import React, { FC } from 'react';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { SyncOutlined } from '@ant-design/icons';

// const Blockers = ({ syncBlockerTile }: any) => {
//   return (
//     <Form.List name="blockerStatistic">
//       {(fields) => (
//         <>
//           {fields.map((field, index) => (
//             <Row className="flex">
//               <Col span={8}>
//                 <Form.Item {...field} name="blockerName" />
//               </Col>
//               <Col span={8}>
//                 <Form.Item {...field} name="total">
//                   <InputNumber readOnly style={{ textAlign: 'center' }} />
//                 </Form.Item>
//               </Col>
//               <Col span={8}>
//                 <Button
//                   style={{ background: '#00ff72', borderRadius: '7px' }}
//                   className="border"
//                   onClick={() => syncBlockerTile(field.key)}
//                 >
//                   <SyncOutlined />
//                 </Button>
//               </Col>
//             </Row>
//           ))}
//         </>
//       )}
//     </Form.List>
//   );
// };

const Blockers: FC<{ field: FormListFieldData; syncBlockerTile: any }> = ({
  field,
  syncBlockerTile,
}) => {
  return (
    <Row className="flex col-span-24">
      <Col span={12}>
        <Form.Item
          {...field}
          name={[field.name, 'blockerName']}
          // style={{ width: '100%', margin: 0 }}
        >
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item
          {...field}
          name={[field.name, 'total']}
          fieldKey={[field.fieldKey, 'total']}
          style={{ margin: 0 }}
        >
          <InputNumber placeholder="Input amount" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          style={{ background: '#00ff72', borderRadius: '7px' }}
          className="border"
          onClick={() => syncBlockerTile(field.fieldKey)}
        >
          <SyncOutlined />
        </Button>
      </Col>
    </Row>
  );
};

export default Blockers;
