import React from 'react';
import Location from 'react-app-location';
import { CalculatorOutlined, CalendarOutlined, CrownOutlined } from '@ant-design/icons';

import { appRoutes, CsLeaderBoardLocation, RouteItem } from '@lib/routes/routes';

import EventCalendarScreen from '@pages/event-calendar';
import LeaderBoardScreen from '@pages/leader-board/leader-board-screen';
import LevelDesignListScreen from '@pages/level-design-list/level-design-list';
import Simulator from '@pages/simulator';
import { UserRole } from '@services/_api';

const SimulatorLocation = new Location('/simulator');
const EventCalendarLocation = new Location('/event-calendar');
const LevelPushingLocation = new Location('/level-pushing');

const routes: RouteItem[] = [
  ...appRoutes,
  {
    name: 'simulator',
    title: 'Simulator',
    icon: <CalculatorOutlined />,
    path: SimulatorLocation.path,
    role: UserRole.Member,
    component: Simulator,
  },
  {
    name: 'event calendar',
    title: 'Event Calendar',
    icon: <CalendarOutlined />,
    path: EventCalendarLocation.path,
    role: UserRole.Member,
    component: EventCalendarScreen,
  },
  // {
  //   name: 'level pushing',
  //   title: 'Level Pushing',
  //   icon: <CalendarOutlined />,
  //   path: LevelPushingLocation.path,
  //   role: UserRole.Member,
  //   component: LevelDesignListScreen,
  // },
  {
    name: 'soloLeaderBoard',
    title: 'LeaderBoard',
    icon: <CrownOutlined />,
    component: LeaderBoardScreen,
    path: CsLeaderBoardLocation.path,
    exact: true,
    role: UserRole.CsViewer,
  },
];

export { routes };
