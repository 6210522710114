import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { useAuth } from '@lib/providers/auth';
import { useGlobalBlueprintDataVersionContext } from '@lib/providers/blueprint-version';
import { blueprintDataService } from '@lib/services/blueprint';

import { BlueprintData } from '@services/_api';
import { odlService } from '@services/odl';

export type SegmentCollection = {
  imageName: string;
  durability: number;
  spawn: number;
  url?: string;
};

export type Zipline = {
  zipline: any;
  imageName: string;
  url?: string;
};

export type ObjectCollection = {
  id: string;
  name: string;
  color?: string;
  size: string;
  haveSegment?: boolean;
  isBlock?: boolean;
  isNotBlocker?: boolean;
  segments?: SegmentCollection[];
  url?: string;
  layer2?: boolean;
  index?: number;
  blocker?: boolean;
  haveMetaData?: boolean;
  tileMetaData?: Record<number, Zipline>;
};

export type TetrominoCollection = {
  shortName?: string;
  name: string;
  defaultValue: string;
  displayPosition: number;
  shapeType: number;
};

export type ObjectCollectionContext = {
  tiles: ObjectCollection[];
  tileMap: Record<string, ObjectCollection>;
  tileBlockers: Record<string, ObjectCollection>;
  tetrominos: TetrominoCollection[];
  loading: boolean;
  fetch: () => Promise<void>;
};

const objectCollectionContext = createContext<ObjectCollectionContext | null>(null);
const { Provider } = objectCollectionContext;

export const ObjectCollectionProvider: FC = ({ children }) => {
  const { currentUser } = useAuth();

  const { selectedKey: version } = useGlobalBlueprintDataVersionContext();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ObjectCollection[]>([]);

  const [tetrominoData, setTetrominoData] = useState<TetrominoCollection[]>([]);

  const tileMap = data.reduce<Record<string, ObjectCollection>>((all, item) => {
    all[item.id] = item;
    return all;
  }, {});

  const tileBlockers = data.reduce<Record<string, ObjectCollection>>((all, item) => {
    if (!item.isNotBlocker) {
      all[item.id] = item;
    }
    return all;
  }, {});

  const fetch = async () => {
    if (!version) return;
    const res = await blueprintDataService.getData(
      version,
      'com.alleylabs.tetris.blueprint.TileCollectionBlueprint',
    );

    const s3FilesRes = await odlService.getAllFilesByFolder('TileImage/');

    const s3InfoUrl: Record<string, string | undefined> = {};
    if (s3FilesRes.ok && s3FilesRes.data) {
      s3FilesRes.data.forEach((s3File) => {
        if (s3File.name.endsWith('png')) s3InfoUrl[s3File.name] = s3File.url ?? s3File.thumbnailUrl;
        else s3InfoUrl[s3File.name] = s3File.thumbnailUrl ?? s3File.url;
      });
    }

    if (res.ok && res.data) {
      const rawData = res?.data?.reduce((acc: ObjectCollection[], item: BlueprintData) => {
        let tileSegment;
        if (item.data.segments) {
          tileSegment = item.data?.segments.reduce((subAcc: any[], subItem: any) => {
            subAcc.push({
              imageName: subItem.imageName,
              durability: subItem.durability,
              spawn: subItem.spawn,
              url: s3InfoUrl[`${subItem.imageName}.jpg`] ?? s3InfoUrl[`${subItem.imageName}.png`],
            });

            return subAcc;
          }, []);
        }

        // const tileMetaDataRecord = item.data?.tileMetaData.reduce(
        //   (_acc: Record<string, any>, subMeta: any) => {
        //     _acc[subMeta.index] = {
        //       zipline: subMeta.zipline,
        //       imageName: subMeta.imageName,
        //       url: s3InfoUrl[`${subMeta.imageName}`],
        //     };
        //     return _acc;
        //   },
        //   {},
        // );

        if (item.state !== 'D') {
          acc.push({
            id: item.data.id,
            name: item.data.name,
            color: item.data.color,
            size: item.data.size,
            haveSegment: item.data.haveSegment,
            isBlock: item.data.isBlock,
            segments: tileSegment,
            url: s3InfoUrl[`${item.data.name}.jpg`] ?? s3InfoUrl[`${item.data.name}.png`],
            layer2: item.data?.layer2,
            blocker: item.data?.blocker,
            isNotBlocker: item.data?.isNotBlocker,
            haveMetaData: item.data?.haveMetaData,
            tileMetaData:
              item?.data?.tileMetaData &&
              item?.data?.tileMetaData.reduce((_acc: Record<string, any>, subMeta: any) => {
                _acc[subMeta.$key] = {
                  zipline: subMeta.zipline,
                  imageName: subMeta.imageName,
                  url:
                    s3InfoUrl[`${subMeta.imageName}.jpg`] ?? s3InfoUrl[`${subMeta.imageName}.png`],
                };
                return _acc;
              }, {}),
          });
        }
        return acc;
      }, []);

      setData(rawData);
    }

    const tetrominoRes = await blueprintDataService.getData(
      version,
      'com.alleylabs.tetris.blueprint.TetrominoBlueprint',
    );

    if (res.ok && tetrominoRes.data) {
      const rawData: TetrominoCollection[] = [];
      // const rawData = tetrominoRes?.data?.reduce(
      // (acc: TetrominoCollection[], item: BlueprintData) =>
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tetrominoRes.data.length; i++) {
        const item = tetrominoRes.data.find((itemData) => itemData.data?.index === i);

        if (item?.state !== 'D' && item?.data) {
          const { name, shortName, defaultValue, displayPosition } = item?.data;
          rawData.push({
            shortName,
            name,
            defaultValue,
            displayPosition,
          } as TetrominoCollection);
        }
      }
      setTetrominoData(rawData);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!currentUser || !version) return;
    fetch();
  }, [currentUser, version]);

  return (
    <Provider
      value={{
        tiles: data,
        tileMap,
        tileBlockers,
        tetrominos: tetrominoData,
        loading,
        fetch,
      }}
    >
      {children}
    </Provider>
  );
};

export const useGlobalObjectCollectionContext = () => {
  const ctx = useContext(objectCollectionContext);
  if (!ctx) {
    throw new Error(
      'useGlobalObjectCollectionContext must be used inside ObjectCollectionProvider',
    );
  }

  return ctx;
};

export default ObjectCollectionProvider;
