import React, { FC } from 'react';
import { Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';
import _ from 'lodash';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { InputNumberItem } from '@pages/map-level/common';
import { TileBlock } from '@pages/map-level/utils/enumeration';
import { useGlobalObjectCollectionContext } from '@providers/object-collection';

const { Item, List } = Form;

const ClearObjects: FC<{ viewOnly: boolean }> = ({ viewOnly }) => {
  const { tileMap } = useGlobalObjectCollectionContext();

  const _tileMap = Object.values(_.cloneDeep(tileMap))
    .filter((item) => !item?.id?.includes('.'))
    .map((ele) => {
      switch (Number(ele.id)) {
        case TileBlock.TileI:
          ele.name = 'TileI';
          break;
        case TileBlock.TileJ:
          ele.name = 'TileJ';
          break;
        case TileBlock.TileL:
          ele.name = 'TileL';
          break;
        case TileBlock.TileO:
          ele.name = 'TileO';
          break;
        case TileBlock.TileS:
          ele.name = 'TileS';
          break;
        case TileBlock.TileT:
          ele.name = 'TileT';
          break;
        case TileBlock.TileZ:
          ele.name = 'TileZ';
          break;

        default:
          break;
      }
      return { ...ele };
    });

  return (
    <div className="mr-3">
      <List name="clearObjects">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Card
                key={field.key}
                tabIndex={index}
                style={{ marginBottom: 12 }}
                extra={
                  true && (
                    <MinusCircleOutlined
                      disabled={viewOnly}
                      style={{ color: 'red' }}
                      className="dynamic-delete-button"
                      onClick={() => {
                        if (!viewOnly) remove(field.name);
                      }}
                    />
                  )
                }
                size="small"
                title={index + 1}
              >
                <Row>
                  <Col span={24}>
                    <div className="flex flex-col gap-3">
                      <Item {...field} name={[field.name, 'objectiveId']} label="Object">
                        <Select
                          disabled={viewOnly}
                          defaultValue={tileMap[0]?.id}
                          style={{ maxWidth: 150, textAlign: 'center' }}
                          showSearch
                          filterOption={
                            (input, option) =>
                              option?.children.toLowerCase().includes(input.toLowerCase())
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        >
                          {_tileMap &&
                            _tileMap?.map((item) => (
                              <Select.Option key={item.id} value={item.id ?? 0}>
                                {item.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Item>

                      <InputNumberItem
                        disabled={viewOnly}
                        field={field}
                        label="Number"
                        name="number"
                      />

                      <Item
                        {...field}
                        label="Is Dynamic"
                        name={[field.name, 'isDynamic']}
                        valuePropName="checked"
                      >
                        <Checkbox />
                      </Item>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
            <Row>
              <Col>
                <Item>
                  <Button
                    disabled={viewOnly}
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Item>
              </Col>
            </Row>
          </>
        )}
      </List>
    </div>
  );
};

export default ClearObjects;
