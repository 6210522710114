import React, { FC } from 'react';
import { Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import _ from 'lodash';

import { useBlueprintDataContext } from '@lib/pages/blueprint-data/components/context';

import { useLevelDataConfigContext } from '@pages/map-level/context';
import { DIFFICULT_TYPE } from '@pages/map-level/utils/constants';
import { GridType, SegmentTransitionType } from '@pages/map-level/utils/enumeration';
import { GENERAL_INIT } from '@pages/map-level/utils/initData';

const GRID_DATA_TYPE = [
  { value: GridType.Segment, label: 'Segment' },
  { value: GridType.Scrolling, label: 'Scrolling' },
];

const SEGMENT_TRANSITION_TYPE = [
  { value: SegmentTransitionType.Vertical, label: 'Vertical' },
  { value: SegmentTransitionType.Horizontal, label: 'Horizontal' },
];

const GeneralInformation: FC<{ viewOnly: boolean }> = ({ viewOnly }) => {
  const {
    formGeneral,
    gridType,
    changeGridType,
    addRow,
    setChangedRow,
    addStage,
    stages,
    maxHeight,
    setStartLine,
    setScrollLinesAmount,
    setMaxWidth,
  } = useLevelDataConfigContext();
  const { tags, setTags } = useBlueprintDataContext();

  const option = [
    { value: 0, label: 'NONE' },
    { value: 1, label: 'REGULAR' },
    { value: 2, label: 'HIGH' },
    { value: 3, label: 'MAX' },
  ];

  const _addRow = (value: number) => {
    if (value < 14) return;
    addRow(value);
  };

  const setMaxRowMethod = (value: number) => {
    setChangedRow(maxHeight - value);
  };

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={GENERAL_INIT}
      layout="horizontal"
      form={formGeneral}
    >
      <Row>
        <Col span={12}>
          <Form.Item rules={[{ required: true }]} label="ID" name="id">
            <InputNumber style={{ width: 150 }} disabled={viewOnly} defaultValue={1} min={0} />
          </Form.Item>
          <Form.Item label="Difficult" name="difficult">
            <Select
              style={{ width: 150, textAlign: 'center' }}
              disabled={viewOnly}
              options={DIFFICULT_TYPE}
            />
          </Form.Item>

          <Form.Item label="Moves" name="moves">
            <InputNumber style={{ width: 150 }} defaultValue={20} disabled={viewOnly} min={0} />
          </Form.Item>

          <Form.Item label="MaxRow" name="maxRow" hidden={gridType === GridType.Scrolling}>
            <InputNumber
              style={{ width: 150 }}
              defaultValue={14}
              min={0}
              onChange={setMaxRowMethod}
            />
          </Form.Item>

          <Form.Item label="Tags">
            <Select
              mode="tags"
              placeholder="Enter custom tags"
              value={tags}
              onChange={setTags}
              style={{ width: 220 }}
              disabled={viewOnly}
            />
          </Form.Item>
          <Form.Item label="Embedded Id" name="embeddedId">
            <Input
              disabled={viewOnly}
              style={{ width: 200 }}
              placeholder="Level_1_EmbeddedConfig"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Grid type" name="gridType">
            <Select
              style={{ width: 150, textAlign: 'center' }}
              options={GRID_DATA_TYPE.map((item) => {
                return {
                  value: item.value,
                  label: item.label,
                };
              })}
              onChange={(value: any) => changeGridType(value)}
              disabled={viewOnly}
            />
          </Form.Item>
          {gridType === GridType.Segment && (
            <>
              <Form.Item label="Number of Stages" name="numberOfStages">
                <InputNumber
                  onChange={(e) => addStage(e)}
                  style={{ width: 150 }}
                  value={stages}
                  min={1}
                  disabled={viewOnly}
                />
              </Form.Item>
              <Form.Item label="Segment Transition Type" name="segmentTransitionType">
                <Select
                  style={{ width: 150, textAlign: 'center' }}
                  options={SEGMENT_TRANSITION_TYPE.map((item) => {
                    return {
                      ...item,
                    };
                  })}
                  disabled={viewOnly}
                />
              </Form.Item>
            </>
          )}

          {gridType === GridType.Scrolling && (
            <>
              <Form.Item label="Rows" name="numberOfRows">
                <InputNumber onChange={_addRow} disabled={viewOnly} value={maxHeight} min={14} />
              </Form.Item>
              <Form.Item label="Scroll Start Line" name="scrollStartLine">
                <InputNumber min={0} disabled={viewOnly} />
              </Form.Item>
              <Form.Item label="Starting Line" name="startingLine">
                <InputNumber min={0} disabled={viewOnly} onChange={setStartLine} />
              </Form.Item>
              <Form.Item label="ScrollLines Amount " name="scrollLinesAmount">
                <InputNumber min={0} disabled={viewOnly} onChange={setScrollLinesAmount} />
              </Form.Item>
            </>
          )}

          <Form.Item label="MaxColumn" name="maxColumn">
            <InputNumber style={{ width: 150 }} defaultValue={10} min={0} onChange={setMaxWidth} />
          </Form.Item>
          <Form.Item label="ForceNextLevel" name="isForceNextLevel" valuePropName="checked">
            <Checkbox disabled={viewOnly} />
          </Form.Item>
        </Col>
      </Row>
      <Divider orientation="left">Expect Data</Divider>
      <Row>
        <Col span={12}>
          <Form.Item label="Win Rate" name="winRate">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} min={0} max={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Move Left" name="moveLeft">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Target Move" name="targetMoveUse">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Target OOM" name="targetOOM">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Target Attempts" name="targetAttemptsToClear">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
        </Col>
      </Row>

      <Divider orientation="left">Smart Tetromino</Divider>
      <Row>
        <Col span={12}>
          <Form.Item label="WinStreakAfterLevel" name="WinStreakAfterLevel">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
          <Form.Item label="LossStreakAfterLevel" name="LossStreakAfterLevel">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} value={stages} />
          </Form.Item>
          <Form.Item label="AverageWinAfterLevel" name="AverageWinAfterLevel">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} min={0} max={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="SmartTetriminoMode" name="SmartTetriminoMode">
            <Select defaultValue={0} disabled={viewOnly} style={{ width: 150 }} options={option} />
          </Form.Item>
          <Form.Item label="StartSmartTetriminosAfterMoves" name="StartSmartTetriminosAfterMoves">
            <InputNumber disabled={viewOnly} style={{ width: 150 }} min={0} max={100} />
          </Form.Item>
          <Form.Item label="IsEnableNextNext" name="IsEnableNextNext" valuePropName="checked">
            <Checkbox disabled={viewOnly} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GeneralInformation;
